import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ourStoryImg from "../images/story.gif"
import ourStoryImg2 from "../images/k-store-4.jpg"
import ourStoryImg3 from "../images/k-store-5.jpg"
import ourStoryImg7 from "../images/k-store-7.jpg"
import ourStoryImg8 from "../images/k-store-8.jpg"

const OurStory = () => (
    <Layout>
        <SEO title="Our Story" />
        <div className="container our-story xl:w-8/12 lg:w-10/12 md:w-10/12 w-full mx-auto">
            <h1 className="text-center our-story__title font-title mt-20 mb-10">Our Story</h1>
            <img src={ourStoryImg} alt="  " className="mt-10 " />
            <p className="our-story__desc mt-12 px-10 text-center leading-loose">
                At Kabuhayan, just like the name every bite is a mouthful.
            </p>
            <p className="our-story__desc mt-5 px-10 text-center leading-loose">
                It all started when our friends and family gathered around the table for some soul food and realized the lack of authentic Filipino quick bites.
                We aim to prepare and serve quality bread and Miryendas that will remind you of home with every taste.
            </p>
            <div className="flex flex-wrap mt-20 md:mt-10 xl:mt-20">
                <div className="w-full lg:w-1/2">
                    <img src={ourStoryImg2} alt="" className="mx-auto mb-5  xl:mb-0" />
                </div>
                <div className="w-full lg:w-1/2 flex items-center">
                    <p className="our-story__desc px-10 text-center leading-loose">
                        Our motto still remains the same, quality over quantity. We put our passion for good food over profit. Don't worry about what you will harvest, be passionate about what you do!
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap mt-20 md:mt-10 xl:mt-20">
                <div className="w-full lg:w-1/2 flex items-center">
                    <p className="our-story__desc px-10 text-center leading-loose">
                        Kabuhayan Cafe comprises of various Filipino breads which is under our Bread Box, and native Filipino Special Delicatessen to cater to all Filipinos in the UAE as a whole.
                    </p>
                </div>
                <div className="w-full lg:w-1/2">
                    <img src={ourStoryImg8} alt="" className="mx-auto mt-5 xl:mt-0" />
                </div>
            </div>
            <div className="flex flex-wrap mt-20 md:mt-10 xl:mt-20">
                <div className="w-full lg:w-1/2">
                    <img src={ourStoryImg3} alt="" className="mx-auto mb-5  xl:mb-0" />
                </div>
                <div className="w-full lg:w-1/2 flex items-center">
                    <p className="our-story__desc px-10 text-center leading-loose">
                        Our Bread Box section consist of freshly baked breads, pastries and cakes and continuously exploring new breads every now and then to the satisfaction of many Filipinos.
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap mt-20 md:mt-10 xl:mt-20">
                <div className="w-full lg:w-1/2 flex items-center">
                    <p className="our-story__desc px-10 text-center leading-loose">
                        Likewise, our special Delights Box are all native delicatessen from the north to the south provinces of the Philippine Archipelago served during special celebration throughout the year and loved by all Filipinos where ever they are.
                    </p>
                </div>
                <div className="w-full lg:w-1/2">
                    <img src={ourStoryImg7} alt="" className="mx-auto mt-5 xl:mt-0" />
                </div>
            </div>

            {/*<img src={signature} alt="" className="text-center w-50 mt-20 mx-auto" />
<p className="our-story__signature__author font-title text-center italic">Kabuhayan Cafe</p>*/}
        </div>
    </Layout>
)

export default OurStory